import React from 'react';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';
import { isNil } from 'lodash';
import { IApplicant } from '../../../../core/interfaces/applicant.interface';

interface IProps {
  visible: boolean;
  applicant: IApplicant | undefined;
  onOk: () => void;
  onCancel: () => void;
}

const ApplicantInfo = (props: IProps) => {
  const { visible, onOk, onCancel, applicant } = props;

  const getAge = (value: boolean | undefined | null): string => {
    if (isNil(value)) {
      return '-';
    }
    return value ? 'Is over 18 years old' : 'Is not over 18 years old';
  };

  const getDriverLicense = (value: boolean | undefined | null) => {
    if (isNil(value)) {
      return '-';
    }
    return value
      ? "Has a valid driver's license"
      : "Doesn't have a valid driver's license";
  };

  const getOwnTools = (value: boolean | undefined | null) => {
    if (isNil(value)) {
      return '-';
    }
    return value
      ? 'Has experience with and owns common carpentry tools'
      : "Doesn't have experience with or own common carpentry tools";
  };

  const getSteelBoots = (value: boolean | undefined | null) => {
    if (isNil(value)) {
      return '-';
    }
    return value
      ? 'Has or is willing to obtain steel toe boots'
      : "Doesn't have nor is willing to obtain steel toe boots";
  };

  return (
    <Modal
      title="Application"
      okText="Convert to Employee"
      cancelText="Cancel"
      onOk={onOk}
      onCancel={onCancel}
      visible={visible}
      width={600}
    >
      <Row>
        <Col span={15}>
          <div className="line__container">
            <p className="line__container__title">Applicant Info</p>
            <div className="info__container">
              <div className="info__container__item">
                <p className="info__container__item__label">FIRST NAME</p>
                <p className="info__container__item__text">
                  {applicant?.firstname}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">LAST NAME</p>
                <p className="info__container__item__text">
                  {applicant?.lastname}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">BIRTH DATE</p>
                <p className="info__container__item__text">
                  {applicant?.birthDate
                    ? moment(applicant.birthDate).format('YYYY-MM-DD')
                    : '-'}
                </p>
              </div>
            </div>
          </div>

          <div className="line__container">
            <p className="line__container__title">Contact Info</p>
            <div className="info__container">
              <div className="info__container__item">
                <p className="info__container__item__label">EMAIL</p>
                <p className="info__container__item__text">
                  {applicant?.email}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">PHONE NUMBER</p>
                <p className="info__container__item__text">
                  {applicant?.phone}
                </p>
              </div>
            </div>
            <div className="info__container">
              <div className="info__container__item">
                <p className="info__container__item__label">
                  ENGLISH PROFICIENCY
                </p>
                <p className="info__container__item__text">
                  {applicant?.englishProficiency || '-'}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">OSHA TRAININGS</p>
                <p className="info__container__item__text">
                  {applicant?.levelOSHA || '-'}
                </p>
              </div>
            </div>
          </div>

          <div className="line__container">
            <p className="line__container__title">Previous Employer</p>
            <div className="info__container">
              <div className="info__container__item">
                <p className="info__container__item__label">NAME</p>
                <p className="info__container__item__text">
                  {applicant?.previousEmployerName || '-'}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">PHONE NUMBER</p>
                <p className="info__container__item__text">
                  {applicant?.previousEmployerPhone || '-'}
                </p>
              </div>
            </div>
          </div>
          <div className="line__container">
            <p className="info__container__item__text">
              {applicant?.meetMinimumRequired
                ? 'Meets minimum requirements for this position'
                : "Doesn't meet minimum requirements for this position"}
            </p>
          </div>
          <div className="line__container">
            <p className="info__container__item__text">
              {getOwnTools(applicant?.hasOwnTools)}
            </p>
          </div>
          <div className="line__container">
            <p className="info__container__item__text">
              {getSteelBoots(applicant?.hasSteelBoots)}
            </p>
          </div>
          <div className="line__container">
            <p className="info__container__item__text">
              {getAge(applicant?.atLeast18)}
            </p>
          </div>
        </Col>
        <Col span={9} className="line__container">
          <p className="info__container__empty__row" />
          <p className="info__container__item__label">WORK EXPERIENCE</p>
          <p className="info__container__item__text">
            {applicant?.workExperience || '-'}
          </p>
          <p className="info__container__empty__row" />
          <p className="info__container__item__label">
            KNOWLEDGE, SKILLS & ABILITIES
          </p>
          <p className="info__container__item__text">
            {applicant?.workKnowledge || '-'}
          </p>
        </Col>
      </Row>
    </Modal>
  );
};

export default ApplicantInfo;
