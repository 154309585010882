import gql from 'graphql-tag';

export const ApplicantFragment = gql`
  fragment Applicant on Applicant {
    id
    firstname
    lastname
    mi
    birthDate
    email
    phone
    previousEmployerName
    previousEmployerPhone
    workExperience
    workKnowledge
    meetMinimumRequired
    atLeast18
    hasOwnTools
    hasSteelBoots
    driverLicense
    englishProficiency
    levelOSHA
    createdAt
    updatedAt
  }
`;
